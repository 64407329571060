import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authSlice",

  initialState: {
    isAuthenticated: false,
  },

  reducers: {
    setAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuthenticated } = authSlice.actions;
export default authSlice.reducer;
