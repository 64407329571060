import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompanyReportFieldsSlice } from "../redux/stateSlice";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  fetchCompanyList,
  fetchUserList,
  fetchUserData,
  updateCompanyReportFields,
  fetchCompanyReportFields,
} from "../database/firebaseApis";
import { setAuthenticated } from "../redux/authSlice";
const { doc, updateDoc, getDoc } = require("firebase/firestore");
const firestore = require("../database/firebase");

export default function Dashboard() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [switches, setSwitches] = React.useState({
    daily: false,
    weekly: false,
    monthly: false,
    faceBiometric: false,
  });
  const [companyList, setCompanyList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [selectedUSer, setSelectedUser] = React.useState("");
  const [resetTimerOn, setResetTimerOn] = React.useState(false);
  const [verifyTimerOn, setVerifyTimerOn] = React.useState(false);
  const [secretTimerOn, setSecretTimerOn] = React.useState(false);
  const reportFieldState = useSelector(
    (state) => state.state.companyReportFields
  );
  const label = { inputProps: { "aria-label": "Switch demo" } };
  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (selectedCompany) {
      // console.log("I am the data", selectedCompany, switches);
      updateCompanyReportFields(selectedCompany, switches);
    }
  }, [switches]);
  React.useEffect(() => {
    // Fetch company list on component mount

    fetchCompanies();
  }, []);
  React.useEffect(() => {
    // console.log("reportFieldStatereportFieldState", reportFieldState);
  }, [reportFieldState]);
  React.useEffect(() => {
    fetchUsers(selectedCompany);
    fetchCompanyReportFieldsComp(selectedCompany);
  }, [selectedCompany]);
  React.useEffect(() => {
    fetchUserDetails(selectedCompany, selectedUSer);
  }, [selectedUSer]);
  const fetchCompanies = async () => {
    try {
      const companies = await fetchCompanyList();
      setCompanyList(companies);
    } catch (error) {
      console.error("Error fetching company list:", error);
    }
  };
  // Function to handle toggling the state of a switch
  const handleToggleSwitch = (switchName) => {
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [switchName]: !prevSwitches[switchName],
    }));
  };

  const fetchCompanyReportFieldsComp = async (companyId) => {
    try {
      const reportData = await fetchCompanyReportFields(companyId);
      // console.log("I am report Data", reportData);
      const { daily, weekly, monthly, faceBiometric } = reportData;
      setSwitches({ daily, weekly, monthly, faceBiometric });
    } catch (err) {
      console.log("Error Occured", err);
    }
  };
  const fetchUsers = async (companyId) => {
    try {
      const users = await fetchUserList(companyId);
      setUserList(users);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const fetchUserDetails = async (companyId, userId) => {
    try {
      const userDetails = await fetchUserData(companyId, userId);
      setUserDetails(userDetails);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const handleReset = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }

      const userDocRef = doc(
        firestore,
        "Company",
        userDetails.companyId,
        "users",
        userDetails.id
      );
      // console.log("User Document Reference:", userDocRef);

      await updateDoc(userDocRef, {
        deviceId: "",
      });

      console.log("Device data cleared successfully in Firebase.");

      // Update the local state to reflect the changes
      setUserDetails((prevUserData) => ({
        ...prevUserData,
        deviceId: "",
      }));

      // Set timerOn to true
      setResetTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setResetTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds
      // console.log("device data is cleared on ui");
    } catch (error) {
      console.error("Error updating device data in Firebase:", error);
    }
  };

  const handleVerify = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }
      console.log("we are user details", userDetails);
      // Send a POST request to the verification email API endpoint
      const response = await fetch(
        "https://us-central1-stemm-trak.cloudfunctions.net/app/api/sendverifiedEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userDetails.Email, // Assuming userDetails.Email contains the user's email
            name: userDetails.Name, // Assuming userDetails.Name contains the user's name
          }),
        }
      );

      // Set timerOn to true
      setVerifyTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setVerifyTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds
      // Handle the response if needed
      console.log("Verification email sent successfully:", response);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const secretKeyHandler = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }
      // console.log("we are user details", userDetails);

      let response = await fetch(
        "https://us-central1-stemm-trak.cloudfunctions.net/app/api/sendSecrateKey",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userDetails.Email, // Assuming userDetails.Email contains the user's email
            name: userDetails.Name, // Assuming userDetails.Name contains the user's name
            secret_Key: Math.floor(Math.random() * 90000) + 10000,
            type: "update",
          }),
        }
      );

      // Set timerOn to true
      setSecretTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setSecretTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds

      // Handle the response if needed
      // console.log("secret keyyyy successfully:", response);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const handleLogout = async () => {
    setSelectedCompany("");
    setSelectedUser("");
    setUserDetails(null);
    dispatch(setAuthenticated(false));
  };

  return (
    <Container className="bg-light border border rounded-lg shadow-lg">
      <Row className="py-3 bg-secondary bg-light align-items-center">
        <Col xs={8}>
          <h3>AdminPanel STEMM One</h3>
        </Col>
        <Col xs={4} className="text-end">
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs lg="3">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Company Name</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {companyList.map((company) => (
                  <TableRow
                    key={company.id}
                    // sx={{ "&:last-child td, &:last-child th": { border: 2 } }}
                    sx={{
                      border: selectedCompany === company.id ? 2 : 0,
                      background:
                        selectedCompany === company.id ? "#E5E4E2" : "",
                    }}
                  >
                    <TableCell
                      onClick={() => {
                        setSelectedCompany(company.id);
                        setSelectedUser("");
                        setUserDetails("");
                      }}
                      component="th"
                      scope="row"
                      sx={{ cursor: "pointer" }}
                    >
                      {company.id}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col xs lg="3">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Users</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  height: "500px",
                  overflowY: "auto",

                  display: "block", // Ensures the TableBody behaves like a block-level element
                }}
              >
                {userList.map((user) => (
                  <TableRow
                    key={user?.id}
                    sx={{
                      border: selectedUSer === user?.id ? 2 : 0,
                      background: selectedUSer === user.id ? "#E5E4E2" : "",
                    }}
                  >
                    <TableCell
                      onClick={() => setSelectedUser(user?.id)}
                      component="th"
                      scope="row"
                      sx={{ cursor: "pointer" }}
                    >
                      {user.id}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col xs lg="6">
          <h6>Attendance Reports</h6>
          <Row className="pb-4">
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <div>Daily</div>
                <Switch
                  checked={switches.daily}
                  onChange={() => handleToggleSwitch("daily")}
                />
              </div>{" "}
            </Col>{" "}
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <div>Weekly</div>
                <Switch
                  checked={switches.weekly}
                  onChange={() => handleToggleSwitch("weekly")}
                />
              </div>{" "}
            </Col>{" "}
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <div>Monthly</div>
                <Switch
                  checked={switches.monthly}
                  onChange={() => handleToggleSwitch("monthly")}
                />
              </div>{" "}
            </Col>
            <Col>
              <div className="d-flex align-items-center">
                <div>Face BioMetrics</div>
                <Switch
                  checked={switches.faceBiometric}
                  onChange={() => handleToggleSwitch("faceBiometric")}
                />
              </div>{" "}
            </Col>
          </Row>
          <Row>
            {" "}
            <div style={{ height: "300px" }}>
              {userDetails?.id !== undefined && (
                <>
                  {" "}
                  <p>{`CompanyStatus
 : ${userDetails?.CompanyStatus}`}</p>
                  <p>{`Branch
 : ${userDetails?.Branch}`}</p>
                  <p>{`Device 
: ${userDetails?.Device}`}</p>{" "}
                  <p>{`Device ID
: ${userDetails?.deviceId}`}</p>
                  <p>{`employmentType
 : ${userDetails?.employmentType}`}</p>
                  <p>{`Department
 : ${userDetails?.Department}`}</p>
                  <p>{`Auto Logout
 : ${userDetails?.autoLogout}`}</p>
                </>
              )}
            </div>
          </Row>

          {selectedUSer && (
            <Row className="mt-4">
              {" "}
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  onClick={handleVerify}
                  variant="contained"
                  disabled={verifyTimerOn}
                >
                  Verify Email
                </Button>
                <Button
                  onClick={secretKeyHandler}
                  variant="contained"
                  disabled={secretTimerOn}
                  href="#contained-buttons"
                >
                  Create / Update Secret Key
                </Button>
                <Button
                  onClick={handleReset}
                  disabled={resetTimerOn}
                  variant="contained"
                  href="#contained-buttons"
                >
                  Reset
                </Button>
              </Stack>
              {secretTimerOn ? (
                <p className="p-1">For Secret Key Wait for two minuts</p>
              ) : (
                ""
              )}
              {resetTimerOn ? (
                <p className="p-1">For Reset Key Wait for two minuts</p>
              ) : (
                ""
              )}
              {verifyTimerOn ? (
                <p className="p-1">For Verify Key Wait for two minuts</p>
              ) : (
                ""
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}
