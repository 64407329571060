import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCompanyReportFields } from "../database/firebaseApis";

export const fetchCompanyReportFieldsSlice = createAsyncThunk(
  "fetchCompanyReportFieldsSlice",
  async (companyID) => {
    return fetchCompanyReportFields(companyID);
  }
);

const stateSlice = createSlice({
  name: "state",

  initialState: {
    companyReportFields: { daily: false, weekly: false, monthly: false },
    faceBiometric: false,
    status: "",
    error: "",
  },

  extraReducers(builder) {
    builder
      .addCase(fetchCompanyReportFieldsSlice.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyReportFieldsSlice.fulfilled, (state, action) => {
        console.log("We are inside the state here", action.payload);
        state.status = "succeeded";
        state.Agents = action.payload;
      })
      .addCase(fetchCompanyReportFieldsSlice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default stateSlice.reducer;
