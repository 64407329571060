import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchAdminUsers } from "../database/firebaseApis";
import { useNavigate } from "react-router-dom";
import { setAuthenticated } from "../redux/authSlice";
import { useDispatch } from "react-redux";

import { auth, provider, signInWithPopup } from "../database/firebase";
import { TextField } from "@mui/material";

export default function AdminMayuresh() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [otpSent, setOtpSent] = React.useState(false);
  const [showVerification, setShowVerification] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [verificationStatus, setVerificationStatus] = React.useState("");
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [isUserVerified, setIsUserVerified] = React.useState(true); // State to track if user is verified
  const [emailError, setEmailError] = React.useState(""); // State to track email error
  const [otpSuccess, setOtpSuccess] = React.useState(false); // State to track OTP success
  const [verificationSuccess, setVerificationSuccess] = React.useState(false); // State to track verification success
  const googleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // console.log("Google login successful:", user);

        // Check if the email of the logged-in user exists in the adminUsers list
        const adminUser = adminUsers.find(
          (admin) => admin.id.toLowerCase() === user.email.toLowerCase()
        );

        if (adminUser) {
          // If the email matches an admin user, proceed to authenticate and navigate
          dispatch(setAuthenticated(true));
          navigate("/dashboard");
        } else {
          // If the email doesn't match, show an error or handle it appropriately
          console.error("Access denied. User is not an admin.");
          alert(
            "Access denied. You are not authorized to access this dashboard."
          );
        }
      })
      .catch((error) => {
        console.error("Error during Google login:", error);
      });
  };

  // console.log("adminUsers---", adminUsers);
  React.useEffect(() => {
    fetchAdminUsers().then((adminUsersData) => {
      setAdminUsers(adminUsersData);
    });
  }, []);

  React.useEffect(() => {
    if (verificationSuccess) {
      setTimeout(() => {
        navigate("/dashboard"); // Navigate to dashboard after a delay
      }, 1000); // Delay in milliseconds
    }
  }, [verificationSuccess, navigate]);

  const handleSendOtp = () => {
    setIsLoading(true);

    // Find the admin user by email to get the name
    const adminUser = adminUsers.find((user) => user.id === email);

    // Check if the entered email matches any admin user ID
    const isAdminUser = !!adminUser;

    if (isAdminUser) {
      // If the email matches an admin user ID, proceed with sending OTP
      fetch(
        "https://admin-otpverification-scuuxs2l5q-uc.a.run.app/api/send-admin-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ to: email, name: adminUser.name }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send OTP");
          }
          return response.json();
        })
        .then((data) => {
          setOtpSuccess(true); // Set OTP success
          setOtpSent(true); // Show OTP sent successfully message
          setTimeout(() => {
            setShowVerification(true);
            // Redirect to the verify email tab after showing OTP sent message
          }, 2000); // Hide the "OTP sent successfully" message after 3 seconds
        })
        .catch((error) => {
          console.error("Error sending OTP:", error);
          setIsLoading(false);
        });
    } else {
      // If the email doesn't match any admin user ID, show error message
      setEmailError("Email is not verified"); // Set email error message
      setIsLoading(false);
    }
  };

  const handleVerify = () => {
    // Check if the entered email exists in adminUsers
    const isAdminUser = adminUsers.some((user) => user.id === email);

    if (!isAdminUser) {
      // If email is not found in adminUsers, show error message
      setVerificationStatus("error");
      return;
    }

    // If the email is found in adminUsers, proceed with OTP verification
    fetch(
      "https://admin-otpverification-scuuxs2l5q-uc.a.run.app/api/verify-admin-otp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ to: email, otp: otp }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Incorrect OTP");
        }
        return response.json();
      })
      .then((data) => {
        // Check if OTP is valid
        if (data.isValid) {
          // Set verification status to success
          setVerificationStatus("success");
          // Set verification success
          setVerificationSuccess(true);
          dispatch(setAuthenticated(true));
        } else {
          // Set verification status to error if OTP is invalid
          setVerificationStatus("error");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        // Set verification status to error in case of any error
        setVerificationStatus("error");
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear email error message when email changes
    setIsUserVerified(true); // Reset user verification status when email changes
    setOtpSuccess(false); // Reset OTP success status when email changes
  };

  return (
    <div style={{ padding: "40px" }}>
      <Container
        className="bg-light p-5 card border rounded-lg shadow-lg d-flex flex-column align-items-center justify-content-center"
        style={{ maxWidth: "500px" }}
      >
        <Row className="py-3 bg-secondary bg-light">
          <h3>AdminPanel TraceForce</h3>
        </Row>
        {!showVerification && (
          <Row className="mt-3">
            <Col>
              {/* <TextField
                fullWidth
                label="Enter Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                className="mb-3"
              /> */}
              {/* <Button
                variant="contained"
                fullWidth
                onClick={handleSendOtp}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button> */}
              <Button
                className="mt-3"
                variant="contained"
                fullWidth
                onClick={googleLogin}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login using Google"
                )}
              </Button>
              {emailError && (
                <Alert variant="filled" severity="warning" className="mt-3">
                  {emailError}
                </Alert>
              )}
              {otpSuccess && (
                <Alert variant="filled" severity="success" className="mt-3">
                  OTP sent successfully.
                </Alert>
              )}
            </Col>
          </Row>
        )}
        {showVerification && (
          <Row className="mt-3">
            <Col>
              <TextField
                fullWidth
                label="Verify OTP"
                variant="outlined"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="mb-3"
              />
              <Button variant="contained" fullWidth onClick={handleVerify}>
                Verify OTP
              </Button>
              {verificationStatus === "error" && (
                <Alert variant="filled" severity="error" className="mt-3">
                  Incorrect OTP. Please try again.
                </Alert>
              )}
            </Col>
          </Row>
        )}
        {verificationSuccess && (
          <Row className="mt-3">
            <Col>
              <Alert variant="filled" severity="success">
                Verification successful.
              </Alert>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
