const { initializeApp } = require("firebase/app");
const {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} = require("firebase/auth");
const { getFirestore } = require("firebase/firestore");

const firebaseConfig = {
  apiKey: "AIzaSyBzgixRWyc10yPeLvh-uqPE7t_k2u0X1aM",
  authDomain: "traceattendance-b0d8b.firebaseapp.com",
  projectId: "traceattendance-b0d8b",
  storageBucket: "traceattendance-b0d8b.appspot.com",
  messagingSenderId: "834433087848",
  appId: "1:834433087848:web:a360216f702ea16e94fedd",
  measurementId: "G-DSF41MTN8S",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const provider = new GoogleAuthProvider();

module.exports = { auth, firestore, provider, signInWithPopup };
