import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import stateSlice from "./stateSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    state: stateSlice,
  },
});

export default store;
